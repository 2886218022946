import { getColumnNameFromMonths } from "./regroupment";

export const ROW_HEIGHT = 38;
export const FOOTER_HEIGHT = 71;

export const SPECIAL_HEADER_WIDTH = 190;
export const NORMAL_HEADER_WIDTH = 175;
export const NORMAL_CELL_WIDTH = 175;

export const HEADER_HEIGHT = 49;
export const LISTING_HEIGHT = 54;

export const SIDEBAR_WIDTH_EXPANDED = 259;
export const SIDEBAR_WIDTH_REDUCED = 100;

export const SCROLLBAR_WIDTH = 8;

function getMonthsColumns(mode, monthsGrouped, RegroupmentValue) {
  const dateColumns = monthsGrouped.map((months, index, array) => ({
    title: getColumnNameFromMonths(months, RegroupmentValue),
    dataIndex: months.join(", "),
    key: index,
    width: 175,
    type: "months",
    isSpecialColumn: false,
    className: mode === "compta" ? "th-compta-date" : "th-treso-date",
    isFirst: index === 0,
    isLast: index === monthsGrouped.length - 1,
  }));

  return dateColumns;
}

function getSpecialHeaderColumns(synthesis, objectif, isListing) {
  const commonCellProps = (col) => {
    return {
      type: col.cellName,
      width: col.cellName === "name" ? 240 : 210,
      className: `create-${col.cellType}`,
    };
  };

  const columns = [
    { cellName: "name", cellType: "category" },
    ...(objectif && !isListing
      ? [{ cellName: "objective", cellType: "objective" }]
      : []),
    ...(synthesis && !isListing
      ? [{ cellName: "synthese", cellType: "synthese" }]
      : []),
  ].map((col) => ({
    dataIndex: col.cellName,
    isSpecialColumn: true,
    key: col.cellName,
    ...commonCellProps(col),
  }));

  return columns;
}

const calculateLeft = (
  index,
  columns,
  scrollLeft,
  isSpecialColumn,
  isSidebarClosed
) => {
  const totalWidth = columns
    .slice(0, index)
    .reduce((acc, col) => acc + col.width, 0);
  return isSpecialColumn ? totalWidth : `${totalWidth - scrollLeft}px`;
};

const calculateTableRowWidth = (columns) => {
  const totalWidth = columns?.reduce((acc, col) => acc + col.width, 0);
  return totalWidth;
};

const calculateCellBodyXPosition = (indexer, objectif, synthese, isListing) => {
  let objectifWidth = objectif ? 240 : 0;
  let syntheseWidth = synthese ? 240 : 0;
  const specialColumnsWidth = !isListing ? objectifWidth + syntheseWidth : 0;

  const totalWidth = indexer * NORMAL_HEADER_WIDTH + specialColumnsWidth + 240;
  return totalWidth;
};

const getCategoryDragPrefix = (category) => {
  if (category?.name) {
    return `category=${category.id}&parent_id=${category.parentId}&isSpecial=${
      category.isSpecial
    }&identifier=${category.identifier}&levelId=${category.levelId}&index=${
      category.index
    }&isRacine=${!!category.isRacine}`;
  } else {
    return "";
  }
};

const getElementDragPrefix = (record, dataIndex, isListing, indexer) => {
  const element = record?.[dataIndex];

  if (!record[dataIndex] && !record.isEmpty && isListing)
    return `indexer=${indexer}&dataIndex=${dataIndex}`;

  return record.name
    ? `category=${
        record?.isRacine ? record?.categoryId : record?.id
      }&isSpecial=${record?.isSpecial}&levelId=${
        record?.levelId
      }&dataIndex=${dataIndex}&indexer=${indexer}&isRacine=${!!record?.isRacine}`
    : `category=${
        record?.category?.isRacine
          ? record.category.categoryId
          : record?.category?.id
      }&isSpecial=${record?.category?.isSpecial}&levelId=${
        record?.category?.levelId
      }&dataIndex=${dataIndex}&specialId=${record?.id}&elementId=${
        record?.[dataIndex]?.id
      }&type=${element?.status}&status=${element?.type}&indexer=${indexer}`;
};

function getCellType(Row, dataIndex) {
  const isEmptyRow = !!Row.isEmptyRow;
  const isEmptyCell = !Row[dataIndex] && !isEmptyRow;
  const hasPrice = !!Row[dataIndex] && !!Row.name;
  const hasElement = !!Row[dataIndex] && !Row.name;

  if (isEmptyRow) {
    return "emptyRow";
  } else if (isEmptyCell) {
    return "emptyCell";
  } else if (hasPrice) {
    return "price";
  } else if (hasElement) {
    return "element";
  } else {
    return "unknown";
  }
}

function calculateStickyPositions(columns) {
  const result = {};

  let currentPosition = 0;

  for (const column of columns) {
    const { key, width } = column;

    result[key] = {
      position: currentPosition,
      className: column.className || "",
    };

    currentPosition += width;
  }

  return result;
}

const flattenCategoryTree = (expandedCategories, category) => {
  const result = [category];

  if (
    expandedCategories.includes(String(category?.id)) &&
    category.children &&
    category.children.length > 0
  ) {
    result.push(
      ...category.children.flatMap((child) =>
        flattenCategoryTree(expandedCategories, child)
      )
    );
  }

  return result;
};

function addRacineToCatgories(categories) {
  if (!Array.isArray(categories) || categories.length === 0) {
    return [];
  }

  const newCategories = [];

  categories.forEach((category) => {
    if (category.isRacine) {
      newCategories.push(category);
      return;
    }

    const noElements = Object.keys(category?.elements).length === 0;

    if (category.children.length === 0) {
      newCategories.push(category);
      return;
    }

    if (noElements) {
      category.children = addRacineToCatgories(category.children);
      newCategories.push(category);
      return;
    }

    const racineChild = {
      id: `${category.id}-racine`,
      categoryId: category.id,
      category: { ...category },
      key: `${category.id}-racine`,
      elements: { ...category?.elements },
      children: [],
      name: `racine de ${category?.name}`,
      isRacine: true,
      isSpecial: category?.isSpecial,
      levelId: category.levelId + 1,
    };

    const newCategory = {
      ...category,
      elements: {},
      children: [racineChild, ...addRacineToCatgories(category.children)],
    };

    newCategories.push(newCategory);
  });

  return newCategories;
}

const scrollManually = (rowsLength) => {
  const rowHeight = 40;

  const totalRowsHeight = rowsLength * rowHeight;

  const scrollPosition = totalRowsHeight - rowHeight;

  smoothScrollTo(scrollPosition);
};

const smoothScrollTo = (targetPosition) => {
  const duration = 500;
  const startTime = performance.now();
  const startScrollTop = document.querySelector(".table-scroll").scrollTop;

  const scrollStep = (timestamp) => {
    try {
      const progress = Math.min((timestamp - startTime) / duration, 1);
      const easedProgress = easeInOutQuad(progress);

      const newScrollTop =
        startScrollTop + (targetPosition - startScrollTop) * easedProgress;
      document.querySelector(".table-scroll").scrollTop = newScrollTop;

      if (progress < 1) {
        window.requestAnimationFrame(scrollStep);
      }
    } catch (error) {
      console.log(error);
    }
  };

  window.requestAnimationFrame(scrollStep);
};

const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

export {
  getMonthsColumns,
  getCategoryDragPrefix,
  scrollManually,
  getSpecialHeaderColumns,
  calculateLeft,
  getCellType,
  getElementDragPrefix,
  calculateStickyPositions,
  calculateTableRowWidth,
  calculateCellBodyXPosition,
  flattenCategoryTree,
  addRacineToCatgories,
};
