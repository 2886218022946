import React from "react";
import Button from "../../Button";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Title from "../../Project/Title";

function DndSpecialModal({ open, id, handleClose, data }) {
  const handleCloseFunc = (id) => {
    handleClose(id);
    data?.abandon();
  };

  return (
    <Overlay show={open} closable={open} onClick={handleCloseFunc}>
      <HeaderOverlay showlogo>
        <Title
          isDelete
          projectTitle={
            <div style={{ width: "95%" }}>
              {data?.mode === "treso"
                ? data?.isSpecial === "1"
                  ? "Vous souhaitez déplacer une écriture depuis une catégorie spéciale de trésorerie. Celle-ci apparaitra donc en mode résultat et vous devrez ajouter une date d'engagement (résultat) ! Confirmez-vous le déplacement ? "
                  : "Vous souhaitez déplacer une écriture vers une catégorie spéciale de trésorerie ! Votre écriture n'apparaitra plus en mode résultat . Confirmez-vous le déplacement ? "
                : data?.isSpecial === "1"
                ? "Vous souhaitez déplacer une écriture depuis une catégorie spéciale de résultat. Celle-ci apparaitra donc en mode trésorerie et vous devrez ajouter une date de trésorerie ! Confirmez-vous le déplacement ? "
                : "Vous souhaitez déplacer une écriture vers une catégorie spéciale de résultat ! Votre écriture n'apparaitra plus en mode trésorerie . Confirmez-vous le déplacement ?"}
            </div>
          }
          subTitle={`Déplacement à contrôler !`}
        />
        <div className="verify-inputs-popup">
          <Button
            text={"Annuler"}
            onClick={() => handleCloseFunc(id)}
            className={"action-button"}
            style={{ width: "200px" }}
          />
          <Button
            text={"Confirmer"}
            onClick={() => data.action()}
            className={"action-button agree-action-button"}
            iconClassName={"delete-icon"}
            style={{ width: "200px" }}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default DndSpecialModal;
