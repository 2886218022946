import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utilities/axios";
import { getCategories, getSynthesis } from "./categories";
import { expandCategories } from "./table";
import { formattedElements } from "../../utilities/groupElementsByDate";
import { getElementsOfObjective } from "./objectives";

const initialState = {
  elementsWithSum: [],
  elements: [],
  status: "idle",
  error: null,
  createError: null,
  elementToEdit: null,
  hoveredCell: null,
  popoverCategory: null,
  simulationInput: null,
  reellePopup: null,
  hoveredFilledCell: null,
  isEditOrAdd: null,
  hoveredPrice: null,
  elementToDelete: null,
  createStatus: "idle",
  sortedValue: "reset",
  hovredTotal: false,
  actionType: "",
  isCreatingInSimulationMode: false,
  duplicatedElementToDelete: null,
  duplicatedElementToEdit: null,
  serieToDelete: null,
  serieToEdit: null,
};

export const getElements = createAsyncThunk(
  "elements/getElements",
  async (queries, thunkAPI) => {
    let data;

    const nofilterChecked =
      !queries.params?.noFilterChecked &&
      queries.params?.filterOptions?.length === 0;

    if (queries.params) {
      delete queries.params?.noFilterChecked;
    }

    if (nofilterChecked) {
      return [];
    }

    try {
      if (!["asc", "desc"].includes(queries?.params?.sort)) {
        delete queries?.params?.sort;
      }
      const response = await axios.get(
        `/api/elements/${queries.mode}/${queries.projectId}`,
        { params: queries.params }
      );
      data = await response.data;
      if (response.status === 200) {
        const formatElementByDate = formattedElements(data?.data.elements);
        return formatElementByDate;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getElementsWithSum = createAsyncThunk(
  "elements/getElementsWithSum",
  async (queries, thunkAPI) => {
    let data;
    delete queries?.params?.sort;

    if (
      Array.isArray(queries?.params?.filterOptions) &&
      queries?.params?.filterOptions?.length === 0
    ) {
      return { monthsBalance: [] };
    }

    try {
      const response = await axios.get(
        `/api/elements/${queries.mode}/${queries.projectId}/sum`,
        { params: queries.params }
      );
      data = await response.data;
      if (response.status === 200) {
        return data?.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createElements = createAsyncThunk(
  "elements/createElements",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/api/elements/${values.categoryId}`, {
        designation: values?.values?.designation,
        amount: values?.values?.amount,
        dateCompta: values?.values?.dateCompta,
        dateTreso: values?.values?.dateTreso,
        type: values?.values?.type,
        status: values?.values?.status,
        ...(values?.values?.accountTresoId && {
          accountTresoId: Number(values?.values?.accountTresoId),
        }),
      });
      data = await response.data;
      if (response.status === 200) {
        if (values.isListing) {
          thunkAPI.dispatch(getElements(values?.listing));
        }

        thunkAPI.dispatch(getCategories(values?.project)).then(() => {
          thunkAPI.dispatch(getSynthesis(values?.synthesis));
          if (
            values?.mode === "compta" &&
            values?.objectiveId &&
            !values?.isListing
          ) {
            thunkAPI.dispatch(getElementsOfObjective(values?.objectiveId));
          }
        });
        thunkAPI.dispatch(getElementsWithSum(values?.sum));

        if (!values.expandedCategories.includes(values.categoryId))
          thunkAPI.dispatch(expandCategories({ key: values.categoryId }));

        if (
          !values.expandedCategories.includes(`${values.categoryId}-racine`)
        ) {
          thunkAPI.dispatch(
            expandCategories({ key: `${values.categoryId}-racine` })
          );
        }

        return data?.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editElement = createAsyncThunk(
  "elements/editElement",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/api/elements/${values.elementId}`, {
        designation: values?.values?.designation,
        categoryId: values?.values?.categoryId,
        amount: values?.values.amount?.toString(),
        dateCompta: values?.values?.dateCompta,
        dateTreso: values?.values?.dateTreso,
        type: values?.values?.type,
        status: values?.values?.status,
        ...(values?.values?.accountTresoId && {
          accountTresoId: Number(values?.values?.accountTresoId),
        }),
      });
      data = await response.data;
      if (response.status === 200) {
        if (values.isListing) {
          thunkAPI.dispatch(getElements(values?.listing));
          thunkAPI.dispatch(getElementsWithSum(values?.sum));
        } else {
          thunkAPI.dispatch(getCategories(values?.project)).then(() => {
            thunkAPI.dispatch(getSynthesis(values?.synthesis));

            if (values?.mode === "compta" && values?.objectiveId) {
              thunkAPI.dispatch(getElementsOfObjective(values?.objectiveId));
            }
          });

          thunkAPI.dispatch(getElementsWithSum(values?.sum));
        }

        return data?.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteElement = createAsyncThunk(
  "elements/deleteElement",
  async (values, thunkAPI) => {
    let data;

    try {
      const response = await axios.delete(`/api/elements/${values.elementId}`);
      data = await response.data;
      if (response.status === 200) {
        if (values.isListing) {
          thunkAPI.dispatch(getElements(values?.listing));
        }
        thunkAPI.dispatch(setElementToDelete(null));
        thunkAPI
          .dispatch(getCategories(values?.project))
          .then(() => thunkAPI.dispatch(getSynthesis(values?.synthesis)));
        if (values?.mode === "compta" && values?.objectiveId) {
          thunkAPI.dispatch(getElementsOfObjective(values?.objectiveId));
        }
        thunkAPI.dispatch(getElementsWithSum(values?.sum));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const duplicateOneElement = createAsyncThunk(
  "elements/duplicateOneElement",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/elements/${values.elementId}/duplicate`,
        {
          dateCompta: values?.values?.dateCompta,
          dateTreso: values?.values?.dateTreso,
          type: values?.values?.type,
          status: values?.values?.status,
          duplicationType: values?.values?.duplicationType,
          repeated: values?.values?.repeated,
        }
      );
      data = await response.data;
      if (response.status === 200) {
        if (values.isListing) {
          thunkAPI.dispatch(getElements(values?.listing));
        }
        thunkAPI
          .dispatch(getCategories(values?.project))
          .then(() => thunkAPI.dispatch(getSynthesis(values?.synthesis)));
        if (values?.mode === "compta" && values?.objectiveId) {
          thunkAPI.dispatch(getElementsOfObjective(values?.objectiveId));
        }
        thunkAPI.dispatch(getElementsWithSum(values?.sum));

        return data?.data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteElementSerie = createAsyncThunk(
  "elements/deleteElementSerie",
  async (values, thunkAPI) => {
    let data;

    try {
      const response = await axios.delete(
        `/api/elements/${values.project.mode}/${values.elementId}/${values.type}`
      );
      data = await response.data;
      if (response.status === 200) {
        if (values.isListing) {
          thunkAPI.dispatch(getElements(values?.listing));
        }
        thunkAPI.dispatch(setDuplicatedElementToDelete(null));
        thunkAPI
          .dispatch(getCategories(values?.project))
          .then(() => thunkAPI.dispatch(getSynthesis(values?.synthesis)));
        thunkAPI.dispatch(getElementsWithSum(values?.sum));
        if (
          values?.mode === "compta" &&
          values?.objectiveId &&
          !values?.isListing
        ) {
          thunkAPI.dispatch(getElementsOfObjective(values?.objectiveId));
        }
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const editElementSerie = createAsyncThunk(
  "elements/editElementSerie",
  async (values, thunkAPI) => {
    let data;
    const fieldsToCheck = [
      "amount",
      "status",
      "type",
      "dateTreso",
      "dateCompta",
    ];

    try {
      const response = await axios.put(
        `/api/elements/${values.project.mode}/${values.elementId}/${values.type}`,
        values.fieldUpdated
      );
      data = await response.data;
      if (response.status === 200) {
        if (
          fieldsToCheck.some((field) =>
            values.fieldUpdated.hasOwnProperty(field)
          )
        ) {
          thunkAPI.dispatch(getElementsWithSum(values?.sum));
        }
        if (values.isListing) {
          thunkAPI.dispatch(getElements(values?.listing));
        } else {
          thunkAPI
            .dispatch(getCategories(values?.project))
            .then(() => thunkAPI.dispatch(getSynthesis(values?.synthesis)));
        }
        if (
          values?.mode === "compta" &&
          values?.objectiveId &&
          !values?.isListing
        ) {
          thunkAPI.dispatch(getElementsOfObjective(values?.objectiveId));
        }
        return data?.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
const slice = createSlice({
  name: "elements",
  initialState,
  reducers: {
    setElementToEdit: (state, action) => {
      state.elementToEdit = action.payload;
    },
    setIsEditOrAdd: (state, action) => {
      state.isEditOrAdd = action.payload;
    },
    setElementToDelete: (state, action) => {
      state.elementToDelete = action.payload;
    },
    openCreatePopOver: (state, action) => {
      state.popoverCategory = action.payload;
    },
    setSimulationInputs: (state, action) => {
      state.simulationInput = action.payload.data;
      state.isCreatingInSimulationMode = action.payload.isCreating;
    },
    openReellePopup: (state, action) => {
      state.reellePopup = action.payload;
    },
    setActionType: (state, action) => {
      state.actionType = action.payload;
    },
    setElementSortByValue: (state, action) => {
      state.sortedValue = action.payload;
    },
    handelHoveredTotal: (state, action) => {
      state.sortedValue = action.payload;
    },
    setDuplicatedElementToDelete: (state, action) => {
      state.duplicatedElementToDelete = action.payload;
    },
    setDuplicatedElementToEdit: (state, action) => {
      state.duplicatedElementToEdit = action.payload;
    },
    setSerieToDelete: (state, action) => {
      state.serieToDelete = action.payload;
    },
    setSerieToEdit: (state, action) => {
      state.serieToEdit = action.payload;
    },
  },
  extraReducers: {
    [getElements.pending]: (state, action) => {
      state.status = "loading";
    },
    [getElements.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.elements = action.payload;
    },
    [getElements.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getElementsWithSum.pending]: (state, action) => {
      state.status = "loading";
    },
    [getElementsWithSum.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.elementsWithSum = action.payload.monthsBalance;
    },
    [getElementsWithSum.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const {
  openReellePopup,
  openCreatePopOver,
  setSimulationInputs,
  setIsEditOrAdd,
  setElementToEdit,
  setElementSortByValue,
  setElementToDelete,
  handelHoveredTotal,
  setActionType,
  setDuplicatedElementToDelete,
  setDuplicatedElementToEdit,
  setSerieToDelete,
  setSerieToEdit,
} = slice.actions;

export default slice;
