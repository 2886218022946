import React from "react";
import "./index.scss";
import { Logo } from "../../components";

function ResponsivePage() {
  return (
    <div className="wrapper-fullpage">
      <div className="logo-desc-container">
        <Logo isLogin={true} />
        <p>Oups... Problème d'affichage !</p>
        <p>
          Si vous êtes sur un écran d'ordinateur, modifiez le zoom ou la
          résolution (minimum 1056 x 600)!
        </p>
        <p>
          Si vous êtes sur un téléphone ou une tablette, nous sommes désolés,
          mais l'appli Tresovista n'y est pas (encore) accessible.
        </p>
      </div>
    </div>
  );
}

export default ResponsivePage;
