const formattedCategories = (categories) => {
  return categories.map((category) => {
    const { id, name, children } = category;
    const treeCategories = {
      value: id,
      title:
        category?.isSpecial === 1 ? (
          <div style={{ color: "#acacac" }}>{name}</div>
        ) : (
          name
        ),
    };
    if (children && children.length > 0) {
      treeCategories.children = formattedCategories(children);
    }

    return treeCategories;
  });
};

export default formattedCategories;
