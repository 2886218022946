import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import AddWritingForm from "./AddWritingForm";
import Button from "../../Button";
import HeaderOverlay from "../../Project/Header";
import Overlay from "../../Overlay";
import Title from "../../Project/Title";

const ModalComponent = ({
  id,
  open,
  headerContent,
  handleClose,
  data,
  content,
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);
  const [lastFocusedInput, setLastFocusedInput] = useState("designation");

  const updateInputFocus = useCallback(
    () =>
      setTimeout(() => {
        const inputToFocus = !!data?.emptyDateTreso
          ? document.getElementsByName("dateTreso")[0]
          : data?.emptyDateCompta
          ? document.getElementsByName("dateCompta")[0]
          : document.getElementsByName(lastFocusedInput)[0];

        if (inputToFocus) {
          inputToFocus.focus();
        }
      }, 100),
    [data?.emptyDateCompta, data?.emptyDateTreso, lastFocusedInput]
  );

  useEffect(() => {
    if (lastFocusedInput && open) {
      updateInputFocus();
    }
    if (!open) {
      setLastFocusedInput("designation");
    }
  }, [open, lastFocusedInput, data?.emptyDateCompta, data?.emptyDateTreso]);

  useEffect(() => {
    if (!showModal) updateInputFocus();
  }, [showModal, data?.emptyDateTreso, data?.emptyDateCompta]);

  const cancelChanges = (id) => {
    handleClose(id);
    setShowModal(false);
  };

  const handleInputFocus = (inputName) => setLastFocusedInput(inputName);
  const handleModalContinue = () => setShowModal(false);

  return (
    <>
      <Modal
        destroyOnClose
        title={
          data?.edit
            ? data?.type === "all"
              ? "Modifier une série d'écritures récurrentes"
              : "Modifier une écriture"
            : "Nouvelle écriture"
        }
        open={open}
        onCancel={() => {
          setShowModal(true);
        }}
        focusTriggerAfterClose={false}
        centred
        style={{ top: 30 }}
        className="add-writing-modal"
        footer={false}
        closable={false}
      >
        <AddWritingForm
          onCancel={() => handleClose(id)}
          edit={data?.edit}
          handleInputFocus={handleInputFocus}
          element={data?.element}
          selectedCategory={data?.selectedCategory}
          projectId={data?.projectId}
          isAction={data?.isAction}
          date={data?.date}
          type={data?.type}
          emptyDateTreso={data?.emptyDateTreso}
          emptyDateCompta={data?.emptyDateCompta}
          dateTresoDndSpecial={data?.dateTresoDndSpecial}
          dateComptaDndSpecial={data?.dateComptaDndSpecial}
          isCreateByDuplication={data?.isCreateByDuplication}
        />
      </Modal>

      {showModal && (
        <Overlay show={open} closable={true} onClick={() => cancelChanges(id)}>
          <HeaderOverlay showlogo>
            <Title
              isDelete
              projectTitle={
                "Lorsque vous saisissez dans une fenêtre type formulaire, vous devez valider la saisie par le bouton « valider »."
              }
              subTitle={"Vous n'avez pas terminé votre saisie!"}
            />
            <div className="verify-inputs-popup">
              <Button
                className={"button-verify-inputs"}
                text={"Abandonner"}
                onClick={() => cancelChanges(id)}
              />

              <Button
                text={"Reprendre la saisie "}
                onClick={handleModalContinue}
                className={
                  "action-button  agree-action-button button-verify-inputs"
                }
                iconClassName={"delete-icon"}
              />
            </div>
          </HeaderOverlay>
        </Overlay>
      )}
    </>
  );
};

export default ModalComponent;
