import { Divider, message } from "antd";
import { Field, Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/Button";
import CustomInput from "../../components/Input";
import Logo from "../../components/Logo";
import useAuth from "../../hooks/useAuth";
import { displayValidationErrors } from "../../utilities/noticeDisplayer";

const Register = () => {
  const { register } = useAuth();

  const LoginSchema = Yup.object().shape({
    firstName: Yup.string().required("Nom est obligatoire"),
    lastName: Yup.string().required("Prénom est obligatoire"),
    email: Yup.string()
      .required("Champ obligatoire")
      .test("valid-email", "Adresse e-mail invalide", function (value) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(value);
      }),
    password: Yup.string()
      .required(
        "⚠️ Mot de passe non valide, veuillez vérifier et saisir à nouveau en respectant les consignes."
      )
      .min(8, "La longueur du mot de passe doit être d'au moins 8 caractères")
      .max(50, "Trop long !"),
  });
  const initialValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await register(values);
      setSubmitting(false);
      resetForm();
      message.success(
        "Veuillez vérifier votre email pour confirmer votre compte."
      );
    } catch (error) {
      setSubmitting(false);
      displayValidationErrors(error);
    }
  };

  return (
    <div className="content left-side left-side-reversed">
      <div className="form-container">
        <div className="promo-container">
          <span className="title">Inscription</span>
          <div className="promotion">
            Période de <br />
            test gratuite !
            <br /> Si vous êtes <br />
            satisfait <br />
            abonnement à
            <br />
            10 euros / mois !
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={LoginSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <>
              <Form className="register-container" autoComplete="off">
                <span className="description">
                  Veuillez saisir votre mail et votre mot de passe
                </span>
                <Field
                  component={CustomInput}
                  label="Nom"
                  name="firstName"
                  type="text"
                  className="auth-input large"
                  placeholder="Nom"
                />
                <Field
                  component={CustomInput}
                  label="Prénom"
                  name="lastName"
                  type="text"
                  className="auth-input large"
                  placeholder="Prénom"
                />
                <Field
                  component={CustomInput}
                  label="Email"
                  name="email"
                  type="text"
                  className="auth-input large"
                  placeholder="Email"
                />
                <Field
                  component={CustomInput}
                  label="Mot de passe"
                  name="password"
                  className="auth-input large"
                  type="password"
                  isPassword={true}
                  placeholder="************"
                />
                <div className="auth-button-wrapper">
                  <Button
                    text={isSubmitting ? "envoi en cours" : "M'inscrire"}
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    className="auth-button"
                    allWidth
                  />
                </div>
              </Form>
            </>
          )}
        </Formik>
        <div className="register-footer">
          <div className="reset-password">
            <label htmlFor="remember">Déjà inscrit ?</label>
            <Link to="/login">Connectez-vous</Link>
          </div>
          <Divider className="login-divider" />
          <span className="text-footer">
            Période de test gratuite ! Si vous êtes satisfait, abonnement à 10
            euros / mois ! 😃
          </span>
        </div>
      </div>

      <div className="sub-container yellow left">
        <div className="circle blue">
          <div className="square-content">
            <Logo isLogin={true} />
            <p>Y voir facilement clair et loin dans ses finances!</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
